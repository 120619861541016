@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
html,* {
    margin: 0;
    padding: 0;
}

html, body{
    height: 100%;
    width: 100%;
}

body {
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    height: 100%;
    width: 100%;
}

main{
    padding-top: 5em;
    height: calc(100% - 5em);
    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

